import {each, filter} from 'lodash'
import {x, x0} from '../_helpers'
import moment from 'moment'


export default class AddressForm {
    constructor() {
        this.triggers = x('[js-address-form]')
        if (this.triggers.length) {
            this.init()
        }
    }

    init() {
        each(this.triggers, el => {
            this.setupConfirmModals(el)

            this.setupAddressCopy(el)
            this.setupBirthDateChangeListener(el)
            this.setupAddressChangeListener(el)
            this.setupAutocomplete(el)
        })
    }

    setupConfirmModals(el) {
        el.modalIndex = 0

        el.addEventListener('submit', e => {
            const modalTriggers = x('[js-form-modal-trigger]', el)

            if (modalTriggers.length) {
                const triggers = filter(modalTriggers, trigger => {
                    const condition = trigger.getAttribute('js-modal-condition')

                    return (!condition || el.classList.contains(condition))
                })

                if (triggers.length && (el.modalIndex < triggers.length)) {
                    e.preventDefault()
                    triggers[el.modalIndex].click()
                }
            }
        })
    }

    setupAddressCopy(el) {
        const addressCopy = x0('[js-address-copy-trigger]', el)
        const shippingAddress = x0('#shipping-address', el)

        if (addressCopy && shippingAddress) {
            addressCopy.addEventListener('change', e => {
                shippingAddress.style.display = e.target.checked ? 'none' : 'block';

                if (e.target.checked) {
                    this.copyMailingAddressToShippingAddress()
                    this.markShippingAddressAsChanged(el)
                }
            })
        }
    }

    setupBirthDateChangeListener(el) {
        const birthDate = x0('#birth_date', el)

        if (birthDate) {
            el.dataset.oldBirthDate = birthDate.value

            birthDate.addEventListener('change', () => {
                this.onBirthDateChanged(el)
            })
        }
    }

    onBirthDateChanged(el) {
        const birthDate = x0('#birth_date', el)

        const oldAge = this.dateToAge(el.dataset.oldBirthDate)
        const newAge = this.dateToAge(birthDate.value)

        el.classList.remove('birth-date-18-down')
        el.classList.remove('birth-date-16-down')
        el.classList.remove('birth-date-18-up')

        if (oldAge !== newAge) {
            if (oldAge >= 18 && newAge < 18) {
                el.classList.add('birth-date-18-down')
            } else if (oldAge >= 16 && newAge < 16) {
                el.classList.add('birth-date-16-down')
            } else if (oldAge < 18 && newAge >= 18) {
                el.classList.add('birth-date-18-up')
            }
        }
    }

    dateToAge(date) {
        return moment().diff(date, 'years')
    }

    setupAddressChangeListener(el) {
        const shippingAddress = x0('#shipping-address', el)

        if (!shippingAddress) {
            return
        }

        const fields = x('input, select', shippingAddress)

        if (fields.length) {
            each(fields, field => {
                field.addEventListener('change', () => {
                    this.markShippingAddressAsChanged(el)
                })
            })
        }
    }

    markShippingAddressAsChanged(el) {
        if (!el.classList.contains('shipping-changed')) {
            el.classList.add('shipping-changed')
        }
    }

    copyMailingAddressToShippingAddress() {
        const fieldMap = {
            shipping_street: 'address',
            shipping_city: 'city',
            shipping_province: 'province',
            shipping_zipcode: 'zipcode',
            shipping_country: 'country'
        }
        each(fieldMap, (source, target) => {
            document.getElementById(target).value = document.getElementById(source).value
        })
    }

    setupAutocomplete(container) {
        const els = x('[js-address-autocomplete]', container)

        each(els, el => {
            const fieldMap = JSON.parse(el.getAttribute('js-address-autocomplete'))

            if (!window.google) {
                console.error('google.maps.places not found')
                return
            }

            let typing = false

            const autocomplete = new window.google.maps.places.Autocomplete(el, {
                componentRestrictions: {country: ['it']},
                fields: ["address_components", "geometry"],
                types: ["address"],
            });

            window.google.maps.event.addDomListener(el, 'keydown', e => {
                if (e.keyCode === 13) {
                    if (typing) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                } else {
                    // means the user is probably typing
                    typing = true;
                }
            })

            autocomplete.addListener("place_changed", () => {
                this.onPlaceChanged(el, autocomplete, fieldMap)
                typing = false

                if (el.name === 'shipping_street') {
                    this.markShippingAddressAsChanged(container)
                }
            });
        })
    }

    onPlaceChanged(el, autocomplete, fields) {
        const place = autocomplete.getPlace()

        let address = {
            street: '',
            street_number: '',
            zipcode: '',
            city: '',
            province: '',
            country: ''
        }

        for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
                case "street_number":
                    address.street_number = component.long_name
                    break
                case "route":
                    address.street = component.short_name
                    break
                case "postal_code":
                    address.zipcode = component.short_name
                    break
                /*case "locality":
                    address.city = component.long_name
                    break*/
                case 'administrative_area_level_3':
                    address.city = component.long_name
                    break
                case "administrative_area_level_2":
                    address.province = component.short_name
                    break
                case "country":
                    address.country = component.long_name
                    break
            }

            el.value = address.street + (address.street_number ? `, ${address.street_number}` : '')
            x0(fields.zipcode).value = address.zipcode
            x0(fields.city).value = address.city
            x0(fields.province).value = address.province
            x0(fields.country).value = address.country
        }
    }
}

